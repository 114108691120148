.ant-layout-header .trigger {
	padding: 0 24px;
	font-size: 18px;
	line-height: 64px;
	cursor: pointer;
	transition: color 0.3s;
}

.ant-pro-sider-logo {
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;
	padding: 16px;
	cursor: pointer;
	transition: padding .3s cubic-bezier(.645, .045, .355, 1);
}

.ant-pro-sider-logo>a {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 32px;
}

.ant-pro-sider-logo img {
	display: inline-block;
	height: 32px;
	vertical-align: middle;
}

.ant-pro-sider-logo h1 {
	display: inline-block;
	height: 32px;
	margin: 0 0 0 12px;
	color: #fff;
	font-weight: 600;
	font-size: 18px;
	line-height: 32px;
	vertical-align: middle;
	animation: pro-layout-title-hide .3s;
}

.ant-layout-header {
	/* background: #fff; */
}

.ant-card-next {
	margin-top: 20px;
}

.ant-background-white {
	background-color: #fff;
}

/* .ant-layout-box{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right: 0;
} */



.header-menu {
	cursor: pointer;
	float: right;
	/* width: 50px; */
	margin-right: 20px;
	font-size: 16px;
}

.ant-page-header {
	padding: 0 0 16px 0;
}

.my-editor {
	border: 1px solid #d9d9d9;
}

@media screen and (max-width: 720px) {
	.search-button-item {
		margin-top: 10px;
	}
}

a.ant-btn {
	padding-top: 0;
}

.message-error-class {
	margin-top: 10vh;
}

.message-error-class .ant-message-notice-content {
	background-color: #ff4d4f;
	color: white;
}

.message-error-class .ant-message-notice-content .ant-message-error .anticon {
	color: white;
}

.ant-input-blue input {
	border: 1px solid #1890ff
}

/* tab标签字体 */
.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
	font-weight: bold;
}

/*表格奇偶行*/
.table-evenRow {
	background: #fafafa;
}

.ant-tabs-tab {  
	background-color: #d5dee4 !important; /* 使用 !important 确保覆盖 */  
	/* 其他样式 */  
}  
	
/* 当 Tab 激活时，你也可以设置不同的背景色 */  
.ant-tabs-tab-active {  
	background-color: #334451 !important;  
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs .ant-tabs-tab-active .ant-tabs-tab-remove, .ant-tabs .ant-tabs-tab-remove:hover {
	color: white;
}
.ant-tabs-tab .ant-tabs-tab-btn, .ant-tabs-tab-btn:hover, .ant-tabs .ant-tabs-tab-remove,.ant-tabs .ant-tabs-tab-remove:hover {
	color: black;
}

/*弹窗头部*/
/* .ant-modal-header {
	border: none;
} */