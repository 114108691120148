body{
    background-color: #f0f2f5;
}

.antd-login-layout {
    background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
    height: 100vh;
    /* background-color: #2f2f2f; */
}
.antd-login-layout-content{

}
.antd-login-layout-content-desc{
    margin-top: 12px;
    margin-bottom: 40px;
    color: rgba(0,0,0,.45);
    font-size: 14px;
}
.antd-login-layout-content-top{
    margin-top: 80px;
    text-align: center;
}
.antd-login-layout-content-header{
    height: 44px;
    line-height: 44px;
}
.antd-login-layout-content-logo{
    vertical-align: top;
    height: 44px;
    margin-right: 16px;
}
.antd-login-layout-content-title{
    position: relative;
    top: 2px;
    color: rgba(2, 2, 2, 0.85);
    font-weight: 600;
    font-size: 33px;
    font-family: Avenir,helvetica neue,Arial,Helvetica,sans-serif;
}
.antd-login-layout-main{
    width: 328px;
    margin: 0 auto;
}