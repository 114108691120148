.bg {
    width: 100vw;
    height: 100vh;
    display: block;
    position: fixed;
}

.data-flex1 {
    position: absolute;
    top: 23%;
    left: 8%;
    width: 40%;
    height: 20%;
    line-height: 20%;
    justify-content:space-between;
    text-align: center;
    padding: 0 10px 0 10px;
}

.data-flex2 {
    position: absolute;
    top: 45%;
    left: 8%;
    width: 40%;
    height: 20%;
    line-height: 20%;
    justify-content: space-between;
    text-align: center;
    padding: 0 10px 0 10px;
}

.data-flex3 {
    position: absolute;
    top: 67%;
    left: 8%;
    width: 40%;
    height: 20%;
    line-height: 20%;
    justify-content: space-between;
    text-align: center;
    padding: 0 10px 0 10px;
}

.data-flex4 {
    position: absolute;
    top: 33%;
    left: 53%;
    width: 40%;
    height: 20%;
    line-height: 20%;
    justify-content: space-around;
    text-align: center;
}

.data-flex5 {
    position: absolute;
    top: 62%;
    left: 53%;
    width: 40%;
    height: 20%;
    line-height: 20%;
    justify-content: space-around;
    text-align: center;
}

.data-div-number {
    font-size: 30px;
    position: relative;
    top: 25%;
    color: white;
    font-weight: bold;
}

.data-div-number2 {
    font-size: 30px;
    position: relative;
    color: white;
}
.data-div-number3 {
    font-size: 30px;
    position: relative;
    color: white;
    top: -10%;
}
.data-div-image {
    width: 8vw;
    height: 8vw;
}
.data-div-desc {
    font-size: 16px;
    position: relative;
    top: -10px;
    color: white;
    font-weight: bold;
}

/* .data-flex2 {
    position: absolute;
    top: 40%;
    left: 12%;
}
.data-flex3 {
    position: absolute;
    top: 60%;
    left: 12%;
}

.data-flex4 {
    position: absolute;
    top: 23%;
    left: 55%;
}

.data-flex5 {
    position: absolute;
    top: 54%;
    left: 55%;
}

.data-flex6 {
    position: absolute;
    top: 68%;
    left: 72%;
}

.data-flex7 {
    position: absolute;
    top: 42%;
    left: 72%;
}

.data-div {
    text-align: center;
    color: white;
    height: 100px;
    margin-left: 7%;
    font-weight: bold;
}

.data-div2{
    text-align: center;
    color: white;
    height: 100px;
    margin-left: 75%;
    font-weight: bold;
}

.data-div-right {
    margin-left: 13%;
}

.data-div-number {
    font-size: 50px;
    position: relative;
    top: 70px;
}

.data-div-number2 {
    font-size: 50px;
    position: relative;
    top: 10px;
}
.data-div-number-right {
    font-size: 18px;
    position: relative;
    top: 10px;
    width: 100px;
}
.data-div-image {
    width: 8vw;
    height: 8vw;
}
.data-div-image-right {
    display: block;
    width: 5vw;
    height: 5vw;
}
.data-div-desc {
    font-size: 26px;
    position: relative;
    top: -30px;
} */